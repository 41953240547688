/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, HashRouter } from 'react-router-dom';

import { store } from '~/store';

const isAuthenticated = () => {
  const { signed } = store.getState().auth;
  return signed;
};
export default function RouteWrapper({
  component: Component,
  isPrivate,
  ...rest
}) {
  const isPrivateUrl = useCallback((hash, loggedIn, privateUrls) => {
    let isAllowed = false;
    // Libera acesso se for menu minhas-rotinas
    if (hash.includes('#/dashboard/minhas-rotinas')) {
      return true;
    }

    if (hash.includes('#/primeiro-acesso')) {
      return true;
    }

    // Libera acesso aos comprovantes de marcação de ponto
    if (hash.includes('/comprovantes-marcacao-ponto')) {
      return false;
    }

    if (hash.includes('#/dashboard/admin/documentos-assinatura-eletronica')) {
      return true;
    }

    // Libera acesso se for menu minhas-ferias
    if (
      hash.includes('#/dashboard/minhas-rotinas/minhas-ferias/programacao/nova')
    ) {
      return true;
    }

    // Libera acesso ao billing se for empresaId 1
    if (
      loggedIn &&
      loggedIn.Usuario &&
      loggedIn.Usuario.EmpresaId === 1 &&
      hash.includes('#/dashboard/admin/billing')
    ) {
      return true;
    }

    // Libera acesso ao admin-master se for empresaId 1
    if (
      loggedIn &&
      loggedIn.Usuario &&
      loggedIn.Usuario.EmpresaId === 1 &&
      hash.includes('#/dashboard/admin/admin-master')
    ) {
      return true;
    }

    // Libera acesso aos periodos aquisitivos de ferias se for empresaId 1032
    if (
      loggedIn &&
      loggedIn.Usuario &&
      loggedIn.Usuario.EmpresaId === 1032 &&
      hash.includes(
        '#/dashboard/admin/importacao-dados/periodos-aquisitivos-ferias',
      )
    ) {
      return true;
    }

    // Libera acesso ao holerite
    if (
      hash.includes('#/dashboard/admin/holerite') &&
      privateUrls.find(
        (element) => element === '#/dashboard/admin/holerite/filtrar',
      )
    ) {
      return true;
    }

    Object.keys(privateUrls).map((key) =>
      hash.includes(privateUrls[key]) ? (isAllowed = true) : null,
    );
    return isAllowed;
  }, []);

  function validatePrivateUrl(hash) {
    const { loggedIn } = store.getState().loginPayload;
    const { signed } = store.getState().auth;

    let privateUrls = [];

    if (signed && privateUrls.length === 0) {
      if (loggedIn && loggedIn.MenuMinhasRotinas) {
        Object.keys(loggedIn.MenuMinhasRotinas).map((key) =>
          loggedIn.MenuMinhasRotinas[key].RecursoFilho.length > 0
            ? Object.keys(loggedIn.MenuMinhasRotinas[key].RecursoFilho).map(
                (childKey) =>
                  loggedIn.MenuMinhasRotinas[key].RecursoFilho[childKey]
                    .RecursoFilho.length > 0
                    ? Object.keys(
                        loggedIn.MenuMinhasRotinas[key].RecursoFilho[childKey]
                          .RecursoFilho,
                      ).map(
                        (grandchildKey) =>
                          (privateUrls = [
                            ...privateUrls,
                            loggedIn.MenuMinhasRotinas[key].RecursoFilho[
                              childKey
                            ].RecursoFilho[grandchildKey].Rota,
                          ]),
                      )
                    : (privateUrls = [
                        ...privateUrls,
                        loggedIn.MenuMinhasRotinas[key].RecursoFilho[childKey]
                          .Rota,
                      ]),
              )
            : (privateUrls = [
                ...privateUrls,
                loggedIn.MenuMinhasRotinas[key].Rota,
              ]),
        );
      }
      if (
        loggedIn &&
        loggedIn.Usuario &&
        loggedIn.Usuario.Configuracao &&
        loggedIn.Usuario.Configuracao.UsarMenuGestor &&
        loggedIn.MenuGestores
      ) {
        Object.keys(loggedIn.MenuGestores).map((key) =>
          loggedIn.MenuGestores[key].RecursoFilho.length > 0
            ? Object.keys(loggedIn.MenuGestores[key].RecursoFilho).map(
                (childKey) =>
                  loggedIn.MenuGestores[key].RecursoFilho[childKey].RecursoFilho
                    .length > 0
                    ? Object.keys(
                        loggedIn.MenuGestores[key].RecursoFilho[childKey]
                          .RecursoFilho,
                      ).map(
                        (grandchildKey) =>
                          (privateUrls = [
                            ...privateUrls,
                            loggedIn.MenuGestores[key].RecursoFilho[childKey]
                              .RecursoFilho[grandchildKey].Rota,
                          ]),
                      )
                    : (privateUrls = [
                        ...privateUrls,
                        loggedIn.MenuGestores[key].RecursoFilho[childKey].Rota,
                      ]),
              )
            : (privateUrls = [...privateUrls, loggedIn.MenuGestores[key].Rota]),
        );
      }
      if (loggedIn && loggedIn.MenuAdmin) {
        Object.keys(loggedIn.MenuAdmin).map((key) =>
          loggedIn.MenuAdmin[key].RecursoFilho.length > 0
            ? Object.keys(loggedIn.MenuAdmin[key].RecursoFilho).map(
                (childKey) =>
                  loggedIn.MenuAdmin[key].RecursoFilho[childKey].RecursoFilho
                    .length > 0
                    ? Object.keys(
                        loggedIn.MenuAdmin[key].RecursoFilho[childKey]
                          .RecursoFilho,
                      ).map(
                        (grandchildKey) =>
                          (privateUrls = [
                            ...privateUrls,
                            loggedIn.MenuAdmin[key].RecursoFilho[childKey]
                              .RecursoFilho[grandchildKey].Rota,
                          ]),
                      )
                    : (privateUrls = [
                        ...privateUrls,
                        loggedIn.MenuAdmin[key].RecursoFilho[childKey].Rota,
                      ]),
              )
            : (privateUrls = [...privateUrls, loggedIn.MenuAdmin[key].Rota]),
        );
      }
    }

    return isPrivateUrl(hash, loggedIn, privateUrls);
  }

  const signed = isAuthenticated();

  if (!signed && isPrivate) {
    if (
      rest.location.pathname.includes('/comprovantes-marcacao-ponto/detalhes')
    )
      return <Redirect to="/comprovantes-marcacao-ponto" />;
    return <Redirect to="/login/" />;
  }

  if (signed && !isPrivate) {
    if (rest.location.pathname.includes('/comprovantes-marcacao-ponto'))
      return <Redirect to="/comprovantes-marcacao-ponto/detalhes" />;
    return <Redirect to="/" />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {signed && validatePrivateUrl(rest.location.hash) ? (
            <HashRouter>
              <Component {...props} />
            </HashRouter>
          ) : (
            <Component {...props} />
          )}
        </>
      )}
    />
  );
}

RouteWrapper.prototype = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
