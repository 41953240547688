/* eslint-disable import/no-cycle */
import { takeLatest, call, put, all, delay } from 'redux-saga/effects';

import { toast } from 'react-toastify';

import { requestBaseApiV1 } from '~/services/request';
import { Types } from '../types';

import { carregarDia3Failure, carregarDia3Success } from './actions';

export function* carregarDia3() {
  const params = {
    url: '/monolito/dia-resumido',
    method: 'GET',
  };

  try {
    const response = yield call(requestBaseApiV1, params);

    if (response.data.success) {
      const { data } = response;

      yield put(carregarDia3Success(data));
      yield delay(2000);
    } else {
      toast.error(response.data.error);
      yield put(carregarDia3Failure());
    }
  } catch (error) {
    toast.error('Falha na autenticação');
    yield put(carregarDia3Failure());
  }
}

export default all([takeLatest(Types.CARREGAR_DIA_3_REQUEST, carregarDia3)]);
