/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

// Componentes Bibliotecas
import { Button, Modal } from 'reactstrap';

// Image
import sucessoMsg from '~/assets/utils/images/imagensFolhaCerta/sucessoMsg.svg';
import insucessoMsg from '~/assets/utils/images/imagensFolhaCerta/insucessoMsg.svg';

class MessageView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  toggle = () => {
    this.props.onClick();
  };

  timeOutMessage = () => {
    if (!this.props.btnClose) {
      setTimeout(() => {
        this.toggle();
      }, 2000);
    }
  };

  render() {
    return (
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        {this.props.open &&
          (this.timeOutMessage(),
          (
            <Modal
              isOpen={this.props.open}
              toggle={this.toggle}
              className={`text-center ${this.props.className}`}
            >
              <div className="p-5" style={{ placeSelf: 'center' }}>
                {this.props.message && (
                  <>
                    <div
                      className="p-1"
                      style={{ color: '#425054', fontSize: '20px' }}
                    >
                      <img
                        width="100px"
                        height="100px"
                        src={
                          this.props.success
                            ? sucessoMsg
                            : this.props.errorImage
                        }
                      />
                    </div>
                    <div
                      className="p-1"
                      style={{ color: '#425054', fontSize: '20px' }}
                    >
                      {this.props.message}
                    </div>
                    <div
                      className="d-flex"
                      style={{ justifyContent: 'center', fontSize: '17px' }}
                    >
                      {this.props.subMessage}
                    </div>
                  </>
                )}
              </div>
              {this.props.btnClose && (
                <Button
                  className="pl-4 pr-4 pt-2 pb-2 mt-2 mr-2 mb-4 btn-sm btn-pill btn text-uppercase"
                  color="white"
                  style={{
                    color: 'white',
                    width: '25%',
                    alignSelf: 'center',
                    backgroundColor: '#003258',
                  }}
                  onClick={this.toggle}
                >
                  ENTENDI
                </Button>
              )}
            </Modal>
          ))}
      </ReactCSSTransitionGroup>
    );
  }
}

MessageView.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  errorImage: PropTypes.element,
  subMessage: PropTypes.string,
  success: PropTypes.bool,
};

MessageView.defaultProps = {
  errorImage: insucessoMsg,
  subMessage: '',
  success: false,
};

export default MessageView;
